import {IconTypes} from '@/app/constants/IconTypes';
import {Lang} from '@/app/lang/Lang';
import {Translations} from '@/app/lang/Translations';
import {StoreBase} from '@/app/stores/StoreBase';
import {FormSelectItem} from '@/app/views/components/form/FormSelect';
import {FormSelectCountryCodePickerItem} from '@/app/views/components/form/FormSelectCountryCodePicker';
import {IIcon} from '@/app/views/components/misc/Icon';

interface IMetaState {
    locales: FormSelectItem<string>[];
    buyerAvailabilityStatuses: FormSelectItem<string>[];
    sellerAvailabilityStatuses: FormSelectItem<string>[];
    countries: FormSelectItem<string>[];
    categories: FormSelectItem<string>[];
    manufyValues: FormSelectItem<string>[];
    transportModes: FormSelectItem<string>[];
    serviceTypes: FormSelectItem<string>[];
    serviceCategoryTypes: FormSelectItem<string>[];
    incoterms: FormSelectItem<string>[];
    annualTurnovers: FormSelectItem<string>[];
    marketingChannels: FormSelectItem<string>[];
    totalEmployees: FormSelectItem<string>[];
    phoneCountryCodes: FormSelectCountryCodePickerItem<string>[];
    madeFor: FormSelectItem<string>[];
    fabrics: FormSelectItem<string>[];
    grammageType: FormSelectItem<string>[];
    productFeatures: FormSelectItem<string>[];
    productStatus: FormSelectItem<string>[];
    orderStatus: FormSelectItem<string>[];
    collaborationStatus: FormSelectItem<string>[];
    statisticTimeframes: FormSelectItem<string>[];
    statisticIntervals: FormSelectItem<string>[];
}

/**
 *
 */
export class MetaStore extends StoreBase<IMetaState> {

    constructor() {
        super();

        this.state.locales = this.idListToPickerItems(Translations.instance.tm('locales'), (id: string, listById: Record<string, string>) => {
            return listById[id] + ' / ' + Lang.localesNative[id]
        });
        this.state.categories = this.idListToPickerItems(Translations.instance.tm('meta.categories'), null, (id: string, listById: Record<string, string>) => {
            return {name: id, solid: true, type: IconTypes.MANUFY,}
        });
        this.state.manufyValues = this.idListToPickerItems(Translations.instance.tm('meta.manufyValues'), null, (id: string, listById: Record<string, string>) => {
            return {name: id, solid: true, type: IconTypes.MANUFY,}
        });
        this.state.buyerAvailabilityStatuses = this.idListToPickerItems(Translations.instance.tm('meta.buyerAvailabilityStatuses'));
        this.state.sellerAvailabilityStatuses = this.idListToPickerItems(Translations.instance.tm('meta.sellerAvailabilityStatuses'));
        this.state.countries = this.idListToPickerItems(Translations.instance.tm('meta.activeCountries'));
        this.state.transportModes = this.idListToPickerItems(Translations.instance.tm('meta.transportModes'), null, (id: string, listById: Record<string, string>) => {
            switch (Lang.t.meta.transportModes[id]) {
                case Lang.t.meta.transportModes.air:
                    return {name: 'plane', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.transportModes.railway:
                    return {name: 'pallet', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.transportModes.road:
                    return {name: 'truck', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.transportModes.water:
                    return {name: 'ship', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.transportModes['n/a']:
                    return {name: 'circle-dot', solid: true, type: IconTypes.FONT_AWESOME,}
            }

            return {name: id, solid: true, type: IconTypes.FONT_AWESOME,}
        });
        this.state.serviceTypes = this.idListToPickerItems(Translations.instance.tm('meta.serviceTypes'), null, (id: string, listById: Record<string, string>) => {
            switch (Lang.t.meta.serviceTypes[id]) {
                case Lang.t.meta.serviceTypes.full_production:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.sampling:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.fabrics:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.cmt:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.sustainability_advice:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.digital_product_passport:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.white_label_blanks:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.techpack:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.agent:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.logistics_shipping:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                case Lang.t.meta.serviceTypes.other:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
                default:
                    return {name: 'other', solid: true, type: IconTypes.MANUFY,}
            }
        });
        this.state.serviceCategoryTypes = this.idListToPickerItems(Translations.instance.tm('meta.serviceCategoryTypes'), null, (id: string, listById: Record<string, string>) => {
            switch (Lang.t.meta.serviceCategoryTypes[id]) {
                case Lang.t.meta.serviceCategoryTypes.design_development:
                    return {name: 'pen-ruler', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.sourcing:
                    return {name: 'scroll', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.manufacturing:
                    return {name: 'scissors', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.packaging_labeling:
                    return {name: 'tags', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.return_reduction:
                    return {name: 'right-left', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.logistics_shipping:
                    return {name: 'dolly', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.repair:
                    return {name: 'bandage', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.resale:
                    return {name: 'share-from-square', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.upcycling:
                    return {name: 'turn-up', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.recycle:
                    return {name: 'recycle', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.traceability:
                    return {name: 'magnifying-glass', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.reporting:
                    return {name: 'clipboard-list', solid: true, type: IconTypes.FONT_AWESOME,}
                case Lang.t.meta.serviceCategoryTypes.certification:
                    return {name: 'certificate', solid: true, type: IconTypes.FONT_AWESOME,}
            }
        });
        this.state.incoterms = this.idListToPickerItems(Translations.instance.tm('meta.incoterms'));
        this.state.annualTurnovers = this.idListToPickerItems(Translations.instance.tm('meta.annualTurnovers'));
        this.state.marketingChannels = this.idListToPickerItems(Translations.instance.tm('meta.marketingChannels'));
        this.state.totalEmployees = this.idListToPickerItems(Translations.instance.tm('meta.totalEmployees'));
        this.state.phoneCountryCodes = this.idListToCountryCodePickerItems(Lang.phoneCountryCodes, (id: string, listById: Record<string, string>) => {
            // TODO: @Krasi - Think of a better approach
            switch (Lang.phoneCountryCodes[id]) {
                case Lang.phoneCountryCodes['+355']:
                    return `${Translations.instance.tm('meta.countries.al')} (${Lang.countriesNative.al})`;
                case Lang.phoneCountryCodes['+376']:
                    return `${Translations.instance.tm('meta.countries.ad')} (${Lang.countriesNative.ad})`;
                case Lang.phoneCountryCodes['+43']:
                    return `${Translations.instance.tm('meta.countries.at')} (${Lang.countriesNative.at})`;
                case Lang.phoneCountryCodes['+375']:
                    return `${Translations.instance.tm('meta.countries.by')} (${Lang.countriesNative.by})`;
                case Lang.phoneCountryCodes['+32']:
                    return `${Translations.instance.tm('meta.countries.be')} (${Lang.countriesNative.be})`;
                case Lang.phoneCountryCodes['+387']:
                    return `${Translations.instance.tm('meta.countries.ba')} (${Lang.countriesNative.ba})`;
                case Lang.phoneCountryCodes['+359']:
                    return `${Translations.instance.tm('meta.countries.bg')} (${Lang.countriesNative.bg})`;
                case Lang.phoneCountryCodes['+385']:
                    return `${Translations.instance.tm('meta.countries.hr')} (${Lang.countriesNative.hr})`;
                case Lang.phoneCountryCodes['+357']:
                    return `${Translations.instance.tm('meta.countries.cy')} (${Lang.countriesNative.cy})`;
                case Lang.phoneCountryCodes['+420']:
                    return `${Translations.instance.tm('meta.countries.cz')} (${Lang.countriesNative.cz})`;
                case Lang.phoneCountryCodes['+45']:
                    return `${Translations.instance.tm('meta.countries.dk')} (${Lang.countriesNative.dk})`;
                case Lang.phoneCountryCodes['+372']:
                    return `${Translations.instance.tm('meta.countries.ee')} (${Lang.countriesNative.ee})`;
                case Lang.phoneCountryCodes['+358']:
                    return `${Translations.instance.tm('meta.countries.fi')} (${Lang.countriesNative.fi})`;
                case Lang.phoneCountryCodes['+33']:
                    return `${Translations.instance.tm('meta.countries.fr')} (${Lang.countriesNative.fr})`;
                case Lang.phoneCountryCodes['+995']:
                    return `${Translations.instance.tm('meta.countries.ge')} (${Lang.countriesNative.ge})`;
                case Lang.phoneCountryCodes['+49']:
                    return `${Translations.instance.tm('meta.countries.de')} (${Lang.countriesNative.de})`;
                case Lang.phoneCountryCodes['+30']:
                    return `${Translations.instance.tm('meta.countries.gr')} (${Lang.countriesNative.gr})`;
                case Lang.phoneCountryCodes['+36']:
                    return `${Translations.instance.tm('meta.countries.hu')} (${Lang.countriesNative.hu})`;
                case Lang.phoneCountryCodes['+354']:
                    return `${Translations.instance.tm('meta.countries.is')} (${Lang.countriesNative.is})`;
                case Lang.phoneCountryCodes['+353']:
                    return `${Translations.instance.tm('meta.countries.ie')} (${Lang.countriesNative.ie})`;
                case Lang.phoneCountryCodes['+39']:
                    return `${Translations.instance.tm('meta.countries.it')} (${Lang.countriesNative.it})`;
                case Lang.phoneCountryCodes['+383']:
                    return `${Translations.instance.tm('meta.countries.xk')} (${Lang.countriesNative.xk})`;
                case Lang.phoneCountryCodes['+371']:
                    return `${Translations.instance.tm('meta.countries.lv')} (${Lang.countriesNative.lv})`;
                case Lang.phoneCountryCodes['+423']:
                    return `${Translations.instance.tm('meta.countries.li')} (${Lang.countriesNative.li})`;
                case Lang.phoneCountryCodes['+370']:
                    return `${Translations.instance.tm('meta.countries.lt')} (${Lang.countriesNative.lt})`;
                case Lang.phoneCountryCodes['+352']:
                    return `${Translations.instance.tm('meta.countries.lu')} (${Lang.countriesNative.lu})`;
                case Lang.phoneCountryCodes['+356']:
                    return `${Translations.instance.tm('meta.countries.mt')} (${Lang.countriesNative.mt})`;
                case Lang.phoneCountryCodes['+373']:
                    return `${Translations.instance.tm('meta.countries.md')} (${Lang.countriesNative.md})`;
                case Lang.phoneCountryCodes['+382']:
                    return `${Translations.instance.tm('meta.countries.me')} (${Lang.countriesNative.me})`;
                case Lang.phoneCountryCodes['+31']:
                    return `${Translations.instance.tm('meta.countries.nl')} (${Lang.countriesNative.nl})`;
                case Lang.phoneCountryCodes['+389']:
                    return `${Translations.instance.tm('meta.countries.mk')} (${Lang.countriesNative.mk})`;
                case Lang.phoneCountryCodes['+47']:
                    return `${Translations.instance.tm('meta.countries.no')} (${Lang.countriesNative.no})`;
                case Lang.phoneCountryCodes['+48']:
                    return `${Translations.instance.tm('meta.countries.pl')} (${Lang.countriesNative.pl})`;
                case Lang.phoneCountryCodes['+351']:
                    return `${Translations.instance.tm('meta.countries.pt')} (${Lang.countriesNative.pt})`;
                case Lang.phoneCountryCodes['+40']:
                    return `${Translations.instance.tm('meta.countries.ro')} (${Lang.countriesNative.ro})`;
                case Lang.phoneCountryCodes['+378']:
                    return `${Translations.instance.tm('meta.countries.sm')} (${Lang.countriesNative.sm})`;
                case Lang.phoneCountryCodes['+381']:
                    return `${Translations.instance.tm('meta.countries.rs')} (${Lang.countriesNative.rs})`;
                case Lang.phoneCountryCodes['+421']:
                    return `${Translations.instance.tm('meta.countries.sk')} (${Lang.countriesNative.sk})`;
                case Lang.phoneCountryCodes['+386']:
                    return `${Translations.instance.tm('meta.countries.si')} (${Lang.countriesNative.si})`;
                case Lang.phoneCountryCodes['+34']:
                    return `${Translations.instance.tm('meta.countries.es')} (${Lang.countriesNative.es})`;
                case Lang.phoneCountryCodes['+46']:
                    return `${Translations.instance.tm('meta.countries.se')} (${Lang.countriesNative.se})`;
                case Lang.phoneCountryCodes['+41']:
                    return `${Translations.instance.tm('meta.countries.ch')} (${Lang.countriesNative.ch})`;
                case Lang.phoneCountryCodes['+90']:
                    return `${Translations.instance.tm('meta.countries.tr')} (${Lang.countriesNative.tr})`;
                case Lang.phoneCountryCodes['+380']:
                    return `${Translations.instance.tm('meta.countries.ua')} (${Lang.countriesNative.ua})`;
                case Lang.phoneCountryCodes['+44']:
                    return `${Translations.instance.tm('meta.countries.uk')} (${Lang.countriesNative.uk})`;
                case Lang.phoneCountryCodes['+379']:
                    return `${Translations.instance.tm('meta.countries.va')} (${Lang.countriesNative.va})`;
                default:
                    return null;
            }
        }, Lang.phoneCountryCodes, null);

        this.state.madeFor = this.idListToPickerItems(Translations.instance.tm('meta.madeFor'));
        this.state.fabrics = this.idListToPickerItems(Translations.instance.tm('meta.fabrics'));
        this.state.grammageType = this.idListToPickerItems(Translations.instance.tm('meta.grammageType'));
        this.state.productFeatures = this.idListToPickerItems(Translations.instance.tm('meta.productFeatures'));
        this.state.productStatus = this.idListToPickerItems(Translations.instance.tm('meta.productStatus'));
        this.state.orderStatus = this.idListToPickerItems(Translations.instance.tm('meta.orderStatus'));
        this.state.collaborationStatus = this.idListToPickerItems(Translations.instance.tm('meta.collaborationStatus'));
        this.state.statisticTimeframes = this.idListToPickerItems(Translations.instance.tm('meta.statisticTimeframes'));
        this.state.statisticIntervals = this.idListToPickerItems(Translations.instance.tm('meta.statisticIntervals'));
    }

    public get locales(): FormSelectItem<string>[] {
        return this.state.locales;
    }

    public get countries(): FormSelectItem<string>[] {
        return this.state.countries;
    }

    public get buyerAvailabilityStatuses(): FormSelectItem<string>[] {
        return this.state.buyerAvailabilityStatuses;
    }

    public get sellerAvailabilityStatuses(): FormSelectItem<string>[] {
        return this.state.sellerAvailabilityStatuses;
    }

    public get categories(): FormSelectItem<string>[] {
        return this.state.categories;
    }

    public get manufyValues(): FormSelectItem<string>[] {
        return this.state.manufyValues;
    }

    public get transportModes(): FormSelectItem<string>[] {
        return this.state.transportModes;
    }

    public get serviceTypes(): FormSelectItem<string>[] {
        return this.state.serviceTypes;
    }

    public get serviceCategoryTypes(): FormSelectItem<string>[] {
        return this.state.serviceCategoryTypes;
    }

    public get incoterms(): FormSelectItem<string>[] {
        return this.state.incoterms;
    }

    public get annualTurnovers(): FormSelectItem<string>[] {
        return this.state.annualTurnovers;
    }

    public get marketingChannels(): FormSelectItem<string>[] {
        return this.state.marketingChannels;
    }

    public get totalEmployees(): FormSelectItem<string>[] {
        return this.state.totalEmployees;
    }

    public get phoneCountryCodes(): FormSelectItem<string>[] {
        return this.state.phoneCountryCodes;
    }

    public get madeFor(): FormSelectItem<string>[] {
        return this.state.madeFor;
    }

    public get fabrics(): FormSelectItem<string>[] {
        return this.state.fabrics;
    }

    public get grammageType(): FormSelectItem<string>[] {
        return this.state.grammageType;
    }

    public get productFeatures(): FormSelectItem<string>[] {
        return this.state.productFeatures;
    }

    public get productStatus(): FormSelectItem<string>[] {
        return this.state.productStatus;
    }

    public get orderStatus(): FormSelectItem<string>[] {
        return this.state.orderStatus;
    }

    public get collaborationStatus(): FormSelectItem<string>[] {
        return this.state.collaborationStatus;
    }

    public get statisticTimeframes(): FormSelectItem<string>[] {
        return this.state.statisticTimeframes;
    }

    public get statisticIntervals(): FormSelectItem<string>[] {
        return this.state.statisticIntervals;
    }

    private idListToPickerItems(listById: Record<string, string>, labelFn: (id: string, listById: Record<string, string>) => string = null, iconFn: (id: string, listById: Record<string, string>) => IIcon = null): FormSelectItem<string>[] {
        return Object.keys(listById).map((id: string): FormSelectItem<string> => {
            return {
                id: id,
                label: (labelFn) ? labelFn(id, listById) : listById[id],
                icon: (iconFn) ? iconFn(id, listById) : null,
            };
        });
    }

    private idListToCountryCodePickerItems(listById: Record<string, string>, labelFn: (id: string, listById: Record<string, string>) => string = null, countryCodes: Record<string, string>, iconFn: (id: string, listById: Record<string, string>) => IIcon = null): FormSelectCountryCodePickerItem<string>[] {
        return Object.keys(listById).map((id: string): FormSelectCountryCodePickerItem<string> => {
            return {
                id: id,
                label: (labelFn) ? labelFn(id, listById) : listById[id],
                countryCode: listById[id],
                icon: (iconFn) ? iconFn(id, listById) : null,
            };
        });
    }
}
